import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import styles from "./DialogConfirm.module.scss";

const DialogConfirm = ({
  title,
  children,
  setOpenModal,
  openModal,
  onDelete,
  idRow = null,
}: TDialogConfirmProps) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = () => {
    setOpenModal({ dialogDelete: false }); // Cierra el modal
  };

  const handleAccept = async () => {
    if (isProcessing) return; // Evita clics múltiples
    setIsProcessing(true); // Desactiva el botón inmediatamente

    try {
      handleClose(); // Cierra el modal
      await onDelete(idRow); // Ejecuta la función principal
    } finally {
      setTimeout(() => setIsProcessing(false), 500); // Espera 500ms antes de reactivar el botón
    }
  };

  return (
    <Dialog
      className={styles["dialog-container"]}
      open={openModal}
      onClose={handleClose}
    >
      <DialogTitle className={styles["dialog-confirm__title"]}>
        {title}
      </DialogTitle>
      <DialogContent className={styles["dialog-confirm__children"]}>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions className={styles["dialog-confirm__actions"]}>
        <Button onClick={handleClose} disabled={isProcessing}>
          Cancelar
        </Button>
        <Button onClick={handleAccept} disabled={isProcessing}>
          {isProcessing ? "Procesando..." : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type TDialogConfirmProps = {
  openModal: boolean;
  title: string;
  children: React.ReactNode;
  setOpenModal: React.Dispatch<React.SetStateAction<any>>; // Permitir cualquier estado
  onDelete: (id?: unknown) => Promise<void>;
  idRow?: unknown;
};

export default React.memo(DialogConfirm);

// import React from "react";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";

// import styles from "./DialogConfirm.module.scss";
// import { TOpenModals } from "types";

// const DialogConfirm = ({
//   title,
//   children,
//   setOpenModal,
//   openModal,
//   onDelete,
//   idRow = null,
// }: TDialogConfirmProps) => {
//   const handleClose = () => {
//     setOpenModal({ dialogDelete: false });
//   };

//   return (
//     <Dialog
//       className={styles["dialog-container"]}
//       open={openModal}
//       onClose={handleClose}
//     >
//       <DialogTitle className={styles["dialog-confirm__title"]}>
//         {title}
//       </DialogTitle>
//       <DialogContent className={styles["dialog-confirm__children"]}>
//         <DialogContentText>{children}</DialogContentText>
//       </DialogContent>
//       <DialogActions className={styles["dialog-confirm__actions"]}>
//         <Button onClick={handleClose}>Cancelar</Button>
//         <Button onClick={() => onDelete(idRow)}>Aceptar</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
// export type TDialogConfirmProps = {
//   openModal: boolean;
//   title: string;
//   children: React.ReactNode;
//   setOpenModal: any;
//   onDelete: (id?: unknown) => Promise<void>;
//   idRow?: unknown;
// };

// export default React.memo(DialogConfirm);
