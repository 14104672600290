import DescriptionIcon from "@mui/icons-material/Description";
import NotesIcon from "@mui/icons-material/Notes";
import { Button, CircularProgress, Container, Stack } from "@mui/material";
import { CustomModal } from "components/presentationals/CustomModal";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { IPROPS } from "interfaces";
import FormArc from "pages/arc/components/FormArc";
import FormReceipt from "pages/receipt/components/FormReceipt";
import React, { useEffect, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { clearErrorsServer } from "reducers/appSlice";
import {
  findConstancy,
  userARCWorker,
  userReciboWorker,
} from "reducers/userSlice";
import { SwiperSlide } from "swiper/react";
import { TOpenModals } from "types";
import imgEmergente from "static/img/VentanaEmergente.png";
import ListGrid from "../../../components/ListGrid";
import {
  getFormatDateString,
  getMonthHelper,
  getTimeWork,
  getYearHelper,
  getPermiso,
} from "../../../utils/helpers";
import { PERMISSIONS } from "utils/enums";
import { DialogConfirm } from "components/presentationals/DialogConfirm";
const ListaCargos = ({
  datos,
  loading,
  activeARC = false,
  multipleReceipt,
}) => {
  const { user } = useAppSelector((state) => state.authReducer);

  const [loadingReceipt, setLoadingReceipt] = React.useState(false);
  const [loadingArc, setLoadingArc] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    modalCreate: false,
    dialogDelete: false,
    modalAssignPermissions: false,
  });

  function getQuincena(quincena: string, mes: string, tcontrato) {
    const quincenaNumber =
      parseInt(quincena) === 1
        ? parseInt(mes) === 1
          ? "01"
          : (parseInt(mes) * 2 - 1).toString().padStart(2, "0")
        : (parseInt(mes) * 2).toString().padStart(2, "0");

    if (tcontrato === "J") return quincenaNumber;
    else if (tcontrato === "F") return quincena;
    else return quincenaNumber[0] === "0" ? quincenaNumber[1] : quincenaNumber;
  }

  function transformMes(mes: string) {
    if (mes.length === 1) {
      return "0" + mes;
    } else {
      return mes;
    }
  }

  const DEFAULT_VALUES: IPROPS | undefined = {
    date: new Date(),
    quincena: "1",
    cedula: datos?.identidad?.split("-")[1],
    ccargo: datos?.ccargo,
    tcontrato: datos?.tipo_persona?.[0],
    nacionalidad: datos?.identidad?.[0],
    clasificacion: datos?.clasificacion,
  };

  const DEFAULT_VALUES_ARC: IPROPS | undefined = {
    anio: new Date().setFullYear(new Date().getFullYear() - 1),
    cedula: datos?.identidad || "",
  };

  const dispatch = useAppDispatch();

  const handleArc = () => {
    setOpenModal({ modalArc: true });
  };
  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    setLoadingReceipt(true);

    const payload: IPROPS = {
      anio: parseInt(getYearHelper(data.date)),
      mes: transformMes(String(getMonthHelper(data.date))),
      quincena: getQuincena(
        data.quincena as string,
        getMonthHelper(data.date),
        data.tcontrato
      ),
      cedula: parseInt(data.cedula as string),
      nacionalidad: data?.nacionalidad,
      ccargo: multipleReceipt ? data?.ccargo : null,
      tcontrato: data.tcontrato,
      clasificacion: data?.clasificacion,
    };
    await dispatch(userReciboWorker(payload));
    await clearErrorsServer();
    setLoadingReceipt(false);
  };

  const onSubmitArc = async (data) => {
    try {
      setLoadingArc(true);
      const payload = {
        anio: getYearHelper(data.anio),
        cedula: datos?.identidad.split("-")[1],
        nacionalidad: datos?.identidad[0],
        tcontrato: datos?.tipo_persona[0],
      };
      await dispatch(userARCWorker(payload));
      await clearErrorsServer();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingArc(false);
    }
  };

  const dataList = useMemo(
    () => [
      {
        title: "NOMBRE Y APELLIDO",
        content: datos?.nombre,
        md: 3,
        show: activeARC,
      },

      {
        title:
          datos?.tipo_persona === "JUBILADO"
            ? "FECHA DE JUBILACIÓN"
            : "FECHA DE INGRESO",
        content: datos?.fecha_ingreso
          ? getFormatDateString(datos?.fecha_ingreso, "dd-MM-yyyy")
          : "N/A",
        md: 3,
        show: true,
      },
      {
        title: "TIEMPO DE SERVICIO",
        content: getTimeWork(datos?.fecha_ingreso) ?? "",
        md: 3,
        show: datos?.tipo_persona === "JUBILADO" ? false : true,
      },
      {
        title: "CÓDIGO DE CARGO",
        content: datos?.ccargo,
        md: 3,
        show: true,
      },
      {
        title: "CARGO",
        content: datos?.dcargo,
        md: 3,
        show: true,
      },
      {
        title: "CÓDIGO DE DEPENDENCIA",
        content: datos?.cdependencia,
        md: 3,
        show: true,
      },
      {
        title: "DEPENDENCIA",
        content: datos?.ddependencia,
        md: 3,
        show: true,
      },
      {
        title: "CONDICIÓN",
        content: datos?.tipo_persona,
        md: 3,
        show: true,
      },
      {
        title: "FECHA DE NACIMIENTO",
        content: datos?.fecha_nacimiento,
        md: 3,
        show: activeARC ? true : false,
      },
    ],
    [datos]
  );

  const handleConfirm = async () => {
    try {
      await handleDataConstancia();
      setOpenModal({ dialogConfirm: false });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmClick = async (data) => {
    setOpenModal({ dialogConfirm: true });
  };

  const handleDataConstancia = async () => {
    try {
      setLoadingButton(true);
      await dispatch(
        findConstancy({
          cedula: datos?.identidad?.split("-")?.[1],
          nacionalidad: datos?.identidad?.[0],
          tcontrato: datos?.tipo_persona[0],
          cargo: datos?.tipo_persona[0] === "F" ? null : datos?.ccargo,
        })
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    console.log("datos", datos);
  }, []);

  return (
    <>
      <SwiperSlide>
        <Container>
          <ListGrid data={dataList} loading={loading} />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent={"space-between"}
            marginTop={4}
          >
            {getPermiso(
              user.permissions,
              PERMISSIONS.SHOW_BUTTON_CONSTANCY
            ) && (
              <Button
                style={{
                  minWidth: "150px",
                }}
                disabled={
                  getPermiso(
                    user.permissions,
                    PERMISSIONS.DISABLED_BUTTON_CONSTANCY
                  )
                    ? true
                    : loadingButton
                }
                startIcon={<NotesIcon />}
                variant="contained"
                onClick={handleConfirmClick}
              >
                {loadingButton ? (
                  <CircularProgress
                    sx={{ height: "20px !important", width: "20px !important" }}
                  />
                ) : (
                  "Generar Constancia"
                )}
              </Button>
            )}

            {activeARC &&
              getPermiso(user.permissions, PERMISSIONS.SHOW_BUTTON_ARC) && (
                <Button
                  onClick={handleArc}
                  disabled={
                    getPermiso(
                      user.permissions,
                      PERMISSIONS.DISABLED_BUTTON_ARC
                    )
                      ? true
                      : loadingArc
                  }
                  variant="contained"
                  fullWidth
                  startIcon={<DescriptionIcon />}
                  style={{
                    maxWidth: "200px",
                  }}
                  sx={{
                    backgroundColor: "#172f69",
                    "&:hover": {
                      backgroundColor: "#121d37",
                    },
                  }}
                >
                  {loadingArc ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Generar ARC-V"
                  )}
                </Button>
              )}

            {getPermiso(user.permissions, PERMISSIONS.SHOW_BUTTON_RECEIPT) && (
              <Button
                style={{
                  minWidth: "200px",
                }}
                startIcon={<NotesIcon />}
                sx={{
                  backgroundColor: "#44ae4f",
                  "&:hover": {
                    backgroundColor: "#297932",
                  },
                }}
                variant="contained"
                disabled={
                  getPermiso(
                    user.permissions,
                    PERMISSIONS.DISABLED_BUTTON_RECEIPT
                  )
                    ? true
                    : false
                }
                onClick={() => setOpenModal({ modalCreate: true })}
              >
                Generar recibo de pago
              </Button>
            )}
          </Stack>
        </Container>

        <CustomModal
          modalTitle={"Generar recibo de pago"}
          openModal={openModal.modalCreate as boolean}
          setOpenModal={setOpenModal}
        >
          <FormReceipt
            onSubmit={onSubmit}
            defaultValues={DEFAULT_VALUES as IPROPS}
            disabledButton={loadingReceipt}
          />
        </CustomModal>

        <CustomModal
          modalTitle={`Generar documento ARC para el trabajador ${datos?.nombre}`}
          openModal={openModal.modalArc as boolean}
          setOpenModal={setOpenModal}
        >
          <FormArc
            onSubmit={onSubmitArc}
            defaultValues={DEFAULT_VALUES_ARC as IPROPS}
            isLoading={loadingArc}
          />
        </CustomModal>
      </SwiperSlide>

      <DialogConfirm
        onDelete={handleConfirm}
        title="Generar constancia"
        openModal={openModal.dialogConfirm}
        setOpenModal={setOpenModal}
      >
        <section>
          <p>
            Ten en cuenta que el limite es de 3 constancias de trabajo cada 15
            dias (quincenal); Asegúrate de que la opción de ventanas emergentes
            esté habilitada en tu navegador web para poder visualizar el PDF
            correctamente.
          </p>

          <picture>
            <img
              style={{ width: "100%" }}
              src={imgEmergente}
              alt="sugerencia"
            />
          </picture>
        </section>
      </DialogConfirm>
    </>
  );
};

/**
 * @method interface IDatosTrabajador
 */
interface IListaCargos {
  cedula: number;
  clasificacion: string;
  codigo_cargo: string;
  codigo_dependencia: string;
  condicion: string;
  dependencia: string;
  dependencia_cargo: string;
  entidad: string;
  estatus: string;
  fecha_ingreso: string;
  origen: string;
}

export interface IListaCargosPage {
  datos: IListaCargos;
  loading: boolean;
}

export default ListaCargos;
